import React from 'react';
import { FlexColumn, ImageWithFallback, RedStripe } from './Common';
import { Carousel } from 'antd';
import styled from 'styled-components';
import { ImagePlaceHolder } from './Placeholder';

const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 1000,
    dots: true,
    autoplaySpeed: 3500,
    swipeToSlide: true,
    focusOnSelect: false,
    autoplay: true,
};

const ImgCarousel = styled(Carousel)`
    max-width: 1200px;
    margin: 0 auto; // Ensure the carousel is centered
    .slick-dots {
        z-index: 1 !important;
    }
    .slick-dots li button:before,
    .slick-dots li.slick-active button:before {
        display: none !important;
        opacity: 0 !important;
    }
    .slick-dots li.slick-active button {
        background: ${({ theme }) => theme.colors.danger};
    }
`;

const ImageContainer = styled.div`
    margin-top: 30px;
    max-width: 1200px; // Added max-width to limit the banner size
    width: 100%;
    img {
        width: 100%;
        max-height: 100%; // Ensure proper scaling of the image
        border-radius: 20px;
        object-fit: cover;
    }
`;

const Banner = styled.div`
    margin-top: 30px;
    outline: none;
    position: relative;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .banner-image {
        cursor: pointer;
        width: 100%;
        height: auto;
        border-radius: 20px;
        object-fit: cover;
        max-width: 100%; // Make sure the image fits within the container
        max-height: 600px; // Adjust for a maximum height
    }

    .banner-name {
        position: absolute;
        width: 90%;
        justify-content: center;
        font-size: 18px;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        align-items: center;
        text-align: center;
    }

    .banner-details {
        font-weight: 800;
        span {
            font-weight: 500;
        }
    }

    @media (max-width: 768px) {
        .banner-name {
            font-size: 14px;
            bottom: 20px;
        }
        .banner-image {
            max-height: 300px; // Reduce image height for mobile
        }
    }

    @media (max-width: 480px) {
        .banner-name {
            font-size: 12px;
            bottom: 10px;
        }
        .banner-image {
            max-height: 250px; // Further reduce image height for smaller screens
        }
    }
`;

export const FinalCarousel = ({ bannerList, loading, goToDetail }) => {
    return (
        <ImgCarousel {...settings}>
            {bannerList?.map(item => (
                <Banner
                    key={item.BannerId}
                    onClick={() => item.EventId !== 0 && goToDetail(item.EventId)}
                >
                    <ImageWithFallback
                        src={item.Banner}
                        alt="banner"
                        className="banner-image"
                    />
                </Banner>
            ))}
            {loading && (
                <ImageContainer>
                    <ImagePlaceHolder />
                </ImageContainer>
            )}
        </ImgCarousel>
    );
};

export const OfferCarousel = ({ bannerList, loading, goToDetail }) => {
    return (
        <ImgCarousel {...settings}>
            {bannerList?.map(item => (
                <Banner
                    key={item.SpotlightId}
                    onClick={() => goToDetail(item.SpotlightId)}
                >
                    <ImageWithFallback
                        src={item.ImageUrl}
                        alt="banner"
                        className="banner-image"
                    />
                    <div className="banner-name">
                        <div className="banner-details">
                            <p className="mx-1">{item.Title}</p>
                            <p className="d-flex">
                                <span className="d-flex align-items-center">
                                    {item.Title}
                                </span>
                            </p>
                        </div>
                        <FlexColumn className="align-items-center">
                            <RedStripe className="p-2 font-16">{item.SubTitle}</RedStripe>
                        </FlexColumn>
                    </div>
                </Banner>
            ))}
            {loading && (
                <ImageContainer>
                    <ImagePlaceHolder />
                </ImageContainer>
            )}
        </ImgCarousel>
    );
};
