/* eslint-disable no-trailing-spaces */
import { Modal, Spin } from 'antd'
import { Buttons, OutlinedButton, PrimaryButton } from 'elements'
import React, { useState } from 'react'
import styled from 'styled-components'

const ModelStyled = styled(Modal)`
    .ant-modal-content {
        background-color: #1e1e1e !important;
        border-radius: 10px;
    }

    .ant-modal-header {
        border-bottom: none;
        background-color: #1e1e1e;
        position: relative;
    }

    .ant-modal-body {
        background-color: #1e1e1e;
        padding: 20px;
    }

    .ant-modal-title {
        color: #fff;
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 18px;
    }

    .ant-input {
        height: 47px !important;
        font-size: 16px !important;
        padding-left: 10px !important;
        margin-bottom: 5px !important;
        background-color: #333 !important;
        color: #fff !important;
        border: 1px solid #555 !important;
        border-radius: 6px !important;
        width: 100%;
        box-sizing: border-box !important;
    }

    .ant-input::placeholder {
        color: #888 !important;
    }

    .ant-checkbox-wrapper {
        color: #fff;
    }

    .ant-checkbox-inner {
        background-color: #2b2b2b;
        border-color: #fff;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ff6700;
        border-color: #ff6700;
    }

    .small-text {
        color: #bbb;
        font-size: 15px;
        margin-top: 5px;
    }

    .continue-button {
        background-color: #1e1e1e !important;
        border: 1px solid #555 !important;
        color: #fff;
        width: 100%;
        height: 50px;
        font-size: 16px;
        border-radius: 9px;
        margin: 0 auto;
        display: block;
    }

    .modal-footer-buttons {
        display: flex;
        justify-content: center;
        gap: 12px;
        padding: 10px 0;
    }

    .submit-button {
        background-color: #ff5252;
        color: #fff;
        border: none;
        width: 47%;
        height: 50px;
        font-size: 16px;
        border-radius: 9px;
        margin-top: 10px;
    }
    .cancel-button {
        background-color: #333 !important;
        color: #fff;
        border: none;
        width: 47%;
        height: 50px;
        font-size: 16px;
        border-radius: 9px;
        margin-top: 10px;
    }
    .cancel-button {
        background-color: #555;
    }

    .modal-close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: none;
        color: #fff;
        font-size: 24px;
        cursor: pointer;
    }
`

const Spiner = styled(Spin)`
    .ant-spin-dot-item {
        background-color: ${({ theme }) => theme.colors.danger} !important;
    }
`

export const Loader = ({ loading, size = 'middle' }) =>
    loading && <Spiner spinning size={size} />

export const PopUpModalContainer = ({
    children,
    onCancel,
    onSubmit,
    width,
    wantSkip = false,
    onSkip,
    ...rest
}) => {
    const [loading, setLoading] = useState(false)

    const handleSkip = async () => {
        setLoading(true)
        if (onSkip) {
            await onSkip()
        }
        setLoading(false)
    }

    return (
        <ModelStyled
            className="custom-modal"
            footer={[
                wantSkip && (
                    <Buttons
                        onClick={handleSkip}
                        key="skip"
                        className="continue-button"
                    >
                        {loading ? (
                            <Spiner size="small" />
                        ) : (
                            'CONTINUE AS GUEST'
                        )}
                    </Buttons>
                ),
                <div className="modal-footer-buttons" key="footer-buttons">
                    <PrimaryButton onClick={onSubmit} className="submit-button">
                        SUBMIT
                    </PrimaryButton>
                    <OutlinedButton
                        onClick={() => onCancel(false)}
                        className="cancel-button"
                    >
                        CANCEL
                    </OutlinedButton>
                </div>,
            ]}
            onCancel={onCancel}
            width={width || 550}
            {...rest}
        >
            {children}
        </ModelStyled>
    )
}
