/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-trailing-spaces */

import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddIcon from '../assets/Icons/add.svg'
import styled from 'styled-components'
import MinusIcon from '../assets/Icons/minus.svg'
import '../screens/Css/BuyTicketModal.css'
import {
    CommonUtility,
    GuestService,
    NotificationStatus,
    StorageConstant,
    BusinesService,
} from 'utility'
import { GetBusinessCategoryList, useQueryString } from 'hooks'
import { PushNotification } from 'components'
import { BrowserUtility } from 'utility/browser-utility'
import { Checkbox, Form, Spin, Tabs } from 'antd'
import { FormTextFormField } from 'elements'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'context'
import logo from '../assets/Icons/GtikitBottomLogo.png'
import { PopUpModalContainer } from 'components/EventForms/PopUpModalContainer'

// UserSchema for form validation
const UserSchema = yup.object().shape({
    CellPhone: yup.string(),
    Email: yup.string().email(),
    SendWhatsAPPTicket: yup.boolean(),
})

// Styled components for the UI
const TicketMain = styled.div`
    width: 95%;
    margin: 0 auto;
    .heading-main {
        display: flex;
        margin: 10px 0;
        justify-content: space-between;
        align-items: center;
    }
    .ant-tabs-content {
        height: calc(100vh - 160px); /* Height adjusted for sticky footer */
        overflow-y: auto;
    }
    @media (max-width: 768px) {
        .heading-main {
            margin: 0 10px 10px;
        }
    }
`

const ModalWrapper = styled.div`
    background: #242631;
    border-radius: 14px;
    padding: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .img {
        width: 50px;
        border-radius: 5px;
        margin-right: 15px; /* Add space between image and text */
    }
`;

const ItemDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1; /* Take available space */
    padding-right: 10px; /* Add some padding to the right */
`;

const OuterBox = styled.div`
    display: flex;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 5px;
    align-items: center;
    gap: 12px;
`

const AddItemBtn = styled.div`
    padding: 6px 12px;
    background: ${({ theme }) => theme.colors.danger};
    height: 35px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    width: auto;
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
`;

const TotalMain = styled.div`
    background: #242631;
    padding: 10px 20px;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 14px;
    margin: 0 auto;
`

const AmountTag = styled.div`
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
`

const PrimaryButton = styled.div`
    background-color: #ff5252;
    color: #fff;
    height: 50px;
    font-size: 16px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 0 20px;
`

// ItemCard component for rendering individual items
const ItemCard = ({ item, items, addItem }) => {
    const { currencyCode } = useAuth(); // Make sure currencyCode is available

    return (
        <ModalWrapper>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={item?.Images?.[0]?.ImageURL} alt="" className="img" />
                <ItemDetails>
                    <span>{item.ItemName}</span>
                    <span>
                        {CommonUtility.currencyFormat(item?.PriceBooks?.[0]?.OriginalPrice || 0, currencyCode)}
                    </span> {/* Properly format price with currency */}
                </ItemDetails>
            </div>
            <div>
                {items[item.ItemID] ? (
                    <OuterBox>
                        <img src={MinusIcon} onClick={() => addItem(item, -1)} alt="" />
                        <span>{items[item.ItemID]?.Qty}</span>
                        <img src={AddIcon} onClick={() => addItem(item, 1)} alt="" />
                    </OuterBox>
                ) : (
                    <AddItemBtn onClick={() => addItem(item, 1)}>
                        Add Item
                    </AddItemBtn>
                )}
            </div>
        </ModalWrapper>
    );
};

// Main component for the cart screen
export const QrCartScreen = () => {
    const [items, setItems] = useState({}) 
    const [loading, setLoading] = useState(false)
    const [openUserModal, setOpenUserModal] = useState(false) 
    const navigate = useNavigate()
    const { s: storetype, id, bei } = useQueryString()
    const { data: businessData } = GetBusinessCategoryList(storetype, id)
    const { setGuest, isisAuthenticated, guest, currencyCode } = useAuth()

    // Load previously selected items
    useEffect(() => {
        const savedItems = BrowserUtility.getObj('selected-items')
        setItems(savedItems || {})
    }, [businessData])

    // Calculate the total amount
    const totalAmount = useMemo(
        () =>
            Object.values(items).reduce((total, item) => total + item.Qty * item.OriginalPrice, 0),
        [items],
    )

    // Function to handle adding items
    // Function to handle adding items
const addItem = (item, qty) => {
    const tmp = items[item.ItemID] || {
        PerOrderLimit: item.PerOrderLimit,
        TotalLimit: item.TotalLimit,
        ProductId: item.ItemID,
        ProductItemId: item.ItemID,
        OriginalPrice:
            item.PriceBooks && item.PriceBooks.length > 0
                ? item.PriceBooks[0].OriginalPrice
                : 0,
        ServiceFee:
            item.PriceBooks && item.PriceBooks.length > 0
                ? item.PriceBooks[0].ServiceFee
                : 0,
        Qty: 0,
    }

    // Update the quantity
    tmp.Qty += qty

    // Handle item removal if quantity is zero
    if (tmp.Qty <= 0) {
        const updatedItems = { ...items }
        delete updatedItems[item.ItemID] // Safely remove the item from the state
        setItems(updatedItems)
    } else {
        // Handle item addition or update
        if (tmp.Qty > tmp.TotalLimit) {
            tmp.Qty = tmp.TotalLimit
        }
        if (tmp.Qty > tmp.PerOrderLimit) {
            tmp.Qty = tmp.PerOrderLimit
        }
        setItems({ ...items, [item.ItemID]: tmp }) // Update state with new quantity
    }
}

    // Handle category tabs for the menu
    const categoryTab = useMemo(() => {
        return businessData?.MenuItems?.Menus?.filter(menu => menu.MenuType === 3)?.[0]?.Items.map(item => ({
            ...item,
            label: item.CategoryName,
            key: item.CategoryID,
            children: (
                <div>
                    {item.Items.map(product => (
                        <ItemCard key={product.ItemID} item={product} items={items} addItem={addItem} />
                    ))}
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <img src={logo} alt="Logo" style={{ width: '80px' }} />
                    </div>
                </div>
            ),
        }))
    }, [businessData, items])

    // Function to handle checkout
    const clickOnNext = async (formData, skip) => { 
        const data = Object.values(items || {}).filter(item => item)
        if (data.length === 0) return

        const params = data.map(item => ({
            ProductId: item.ProductId,
            ProductItemId: item.ProductItemId,
            Qty: item.Qty,
        }))
        setLoading(true)
        try {
            const result = await BusinesService.addToCart(storetype, {
                CartInfo: params,
                ButtonType: 2,
                IsDelete: false,
                ButtonId: 0,
                SendWhatsAPPTicket: formData.SendWhatsAPPTicket || false,
                IsGuestCheckout: skip || false,
                BusinessEventUId: bei || id,
                UserInfo: {
                    Email: formData.Email || '',
                    CellPhone: formData.CellPhone || '',
                    SendWhatsAPPTicket: formData.SendWhatsAPPTicket || false,
                    IsGuestCheckout: skip || false,
                },
            })
            BrowserUtility.save('checkout-token', result.Result.CheckoutToken)
            const { ProductBucketId } = result.Result
            BrowserUtility.saveObj(`product-buy-now-${ProductBucketId}`, result?.Result)
            setLoading(false)
            setGuest(false)
            navigate(`/qr/cart-confirmation/${ProductBucketId}?storetype=${storetype}`)
        } catch (error) {
            setLoading(false)
            PushNotification('Error while proceeding to checkout', NotificationStatus.error)
        }
    }

    // Handle next button click
    const onNext = () => { 
        if (!isisAuthenticated && !guest) {
            setOpenUserModal(true)
        } else {
            clickOnNext({}, guest)
        }
    }

    const saveUser = (formData, skip) => {
        if (formData) {
            const data = Object.values(items || {}).filter(d => d)
            if (data.length > 0) {
                clickOnNext(formData, skip)
                setOpenUserModal(false)
            } else {
                PushNotification('Please select items', NotificationStatus.error)
            }
        } else {
            setOpenUserModal(false)
        }
    }

    return (
        <TicketMain>
            <div className="heading-main">
                {businessData?.BusinessFullName}
            </div>
            {businessData?.BusinessUId && (
                <Tabs defaultActiveKey="1" items={categoryTab} />
            )}
            <TotalMain>
                <AmountTag>Total: {CommonUtility.currencyFormat(totalAmount, currencyCode)}</AmountTag>
                <PrimaryButton onClick={onNext}>
                    {loading ? <Spin size="medium" /> : 'Next'}
                </PrimaryButton>
            </TotalMain>
            <EmailPhoneModal open={openUserModal} onClose={saveUser} />
        </TicketMain>
    )
}

// Modal component to capture user information
export const EmailPhoneModal = ({ open, onClose }) => {
    const { control, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(UserSchema),
    })
    const SendWhatsAPPTicket = watch('SendWhatsAPPTicket')
    const { countryCode, setGuest } = useAuth()

    useEffect(() => {
        reset({ Email: '' })
    }, [open])

    const submit = async formData => {
        onClose(formData)
    }

    const guestCheckout = async () => {
        const response = await GuestService.addGuestCheckout(countryCode)
        BrowserUtility.save(StorageConstant.token, response?.Result)
        BrowserUtility.save(StorageConstant.guest, true)
        setGuest(true)
        onClose({}, true)
    }

    return (
        <PopUpModalContainer open={open} onSubmit={handleSubmit(submit)} onSkip={guestCheckout} onCancel={() => onClose(false)} wantSkip={true}>
            <Form>
                <div className="modal-title text-center my-3">
                    <h2 style={{ color: 'white', textAlign: 'left', fontWeight: 'bold', marginBottom: '32px' }}>User Info</h2>
                </div>
                <div className="mt-2 mx-4">
                    <Form.Item className="col-12">
                        <FormTextFormField control={control} name="Email" placeholder="Email" errors={errors?.Email} />
                    </Form.Item>
                    <Form.Item className="col-12">
                        <FormTextFormField control={control} name="CellPhone" placeholder="Phone Number" errors={errors?.CellPhone} />
                    </Form.Item>
                    <div className="col-12 mb-3 pointer" onClick={() => setValue('SendWhatsAPPTicket', !SendWhatsAPPTicket)} style={{ fontWeight: 'bold' }}>
                        <Checkbox checked={SendWhatsAPPTicket} onChange={() => setValue('SendWhatsAPPTicket', !SendWhatsAPPTicket)} />
                        &nbsp;&nbsp; Send payment receipt via WhatsApp
                    </div>
                    <div className="d-flex small-text">
                        <span>*</span>
                        <div className="ms-1" style={{ color: '#D3D3D3' }}>Please provide a valid email and phone number to receive your order confirmation.</div>
                    </div>
                </div>
            </Form>
        </PopUpModalContainer>
    )
}
