import React, { useEffect } from 'react';
import {
    EventName,
    DescSection,
    Description,
    DateLocationSection,
    DateWapper,
    WapperIcon,
    WapperMain,
    WapperSub,
    EventDetailsBtn,
} from './style';
import location from '../../assets/Icons/Location.svg';
import calender from '../../assets/Icons/Calendar.svg';
import dollar from '../../assets/Icons/Dollar.svg';
import '../../screens/Css/EventDetail.css';
import { ButtonAndSocialLinks } from '../ticket/ButtonAndSocialLinks';
import { DateUtility, DayJSDateFormat, AuthPopup } from 'utility'; // Combine all imports from 'utility' here
import { TermsAndCondition } from '../../page-components/events/TermsAndCondition';
import { FAQNew } from '../../page-components/events/FAQNew';
import { Advertisement } from './Advertisement';
import { useAuth } from 'context';
import { useLayout } from 'layout';

export const EventDetails = ({ data, refreshList, currencySign }) => {
    const { guest, setGuest, isAuthenticated } = useAuth();
    const { toggleModal } = useLayout();

    // Reset guest status to false when the page is loaded
    useEffect(() => {
        setGuest(false);
    }, [setGuest]);

    // This function will be triggered when the user clicks "Buy Ticket"
    const handleBuyTicketClick = () => {
        if (!isAuthenticated && !guest) {
            // If the user is not authenticated, show the login popup
            toggleModal(AuthPopup.login);
        } else {
            // Proceed with the buying flow if the user is authenticated or a guest
            console.log('Proceed to ticket purchase...');
        }
    };

    const flyerId = 13;

    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-8">
                    <EventName>{data?.EventName}</EventName>
                    <DescSection>
                        <input type="Checkbox" id="check" />
                        <img
                            className="col-12 mb-3 flyer-image"
                            src={
                                data?.EventImages?.find(
                                    ele => ele.ImageCategoryId === flyerId,
                                )?.Image
                            }
                            alt=""
                        />
                        <Description>Description</Description>
                        <pre className="content shown">{data?.Description}</pre>
                    </DescSection>
                    <DateLocationSection>
                        <div className="row">
                            <DateWapper className="col-sm-12 col-md-12 pb-md-3">
                                <WapperIcon>
                                    <img src={calender} alt="icon" />
                                </WapperIcon>
                                <div className="details">
                                    <WapperMain>
                                        {DateUtility.getDateTime(
                                            data?.EventStartDateTime,
                                            DayJSDateFormat.dateMonth,
                                        )}
                                    </WapperMain>
                                    <WapperSub>
                                        {DateUtility.getEventTime(
                                            data?.EventStartDateTime,
                                            data?.ShowEndTime &&
                                                data?.EventEndDateTime,
                                            'Onwards',
                                        )}
                                    </WapperSub>
                                </div>
                            </DateWapper>
                            <DateWapper className="col-sm-12 col-md-12 pb-md-3">
                                <WapperIcon>
                                    <img src={location} alt="icon" />
                                </WapperIcon>
                                <div className="details">
                                    <WapperMain>{data?.PlaceName}</WapperMain>
                                    <WapperSub>{data?.AddressLine1}</WapperSub>
                                </div>
                            </DateWapper>
                            {data?.IsTicket && !data?.IsFreeEvent && (
                                <DateWapper className="col-sm-12 col-md-12 pb-md-3">
                                    <WapperIcon>
                                        <img src={dollar} alt="icon" />
                                    </WapperIcon>
                                    <div className="details">
                                        {currencySign} {data?.MinRatePrice || 0} - {currencySign} {data?.MaxRatePrice || 0}
                                        <WapperSub>
                                            {data?.ProviderRefundPolicy}
                                            <br />
                                            {data?.GtikitRefundPolicy}
                                        </WapperSub>
                                    </div>
                                </DateWapper>
                            )}
                            {data?.ExtraLink && (
                                <div className="view-more-link">
                                    <a href={data?.ExtraLink} target=" _blank ">
                                        View More
                                    </a>
                                </div>
                            )}
                        </div>
                    </DateLocationSection>
                    <div className="d-md-none pt-2">
                        <ButtonAndSocialLinks
                            data={data}
                            refreshList={refreshList}
                            currencySign={currencySign}
                            onBuyTicket={handleBuyTicketClick} // Add onBuyTicket prop to trigger the buy ticket flow
                        />
                    </div>
                    <div className="terms-condition">
                        <TermsAndCondition data={data} />
                    </div>
                    <div className="terms-condition pt-4">
                        <FAQNew data={data} />
                    </div>
                </div>
                <EventDetailsBtn className="col-sm-12 col-md-4 d-sm-none d-md-block">
                    <ButtonAndSocialLinks
                        data={data}
                        refreshList={refreshList}
                        currencySign={currencySign}
                        onBuyTicket={handleBuyTicketClick} // Add onBuyTicket prop here as well
                    />
                    <Advertisement data={data} />
                </EventDetailsBtn>
            </div>
            <div className="row mb-5">
                <div className="col-sm-12 d-md-none">
                    <Advertisement data={data} />
                </div>
            </div>
        </>
    );
};
